<!-- 模板 -->
<template>
  <div>
    <el-carousel
      :interval="4000"
      type="card"
      indicator-position="none"
      height="350px"
    >
      <el-carousel-item v-for="item in list[0].images" :key="item">
        <h3 class="medium">
          <img class="me_s" :src=" $baseUrl + item" alt="">
        </h3>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  props: { list: [] },
  components: {},
  computed: {},
  filters: {},
  methods: {},
  created() {},
  mounted() {
    console.log(this.list);
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
.el-carousel__indicator {
  display: none;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.me_s{
  width: 100%;
  height: 350px;
}
</style>
