<!-- 模板 -->
<template>
  <div class="">
    <div class="contants">
      <headers class="top" :active="0"></headers>
      <img :src="$baseUrl + banner_img" class="banab" alt="" />
      <div class="china">
        <div class="wu">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ zudao_data.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Chinese Footpath</div>
          <div class="guo">
            <div class="guo_l">
              <div class="tits">{{ zudao_data.name }}</div>
              <div class="ctit">
                <p v-html="zudao_content"></p>
              </div>
            </div>
            <div class="guo_r">
              <div class="aimg">
                <img class="aimg" :src="$baseUrl + zudao_img" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="mountanin">
          <img src="../assets/image/5.png" alt="" class="s_imgl" />
          <img src="../assets/image/5.png" alt="" class="s_imgr" />
        </div>
      </div>
      <!-- 门店照片 -->
      <div class="photo">
        <div class="baner">
          <el-carousel
            height="800px"
            indicator-position="none"
            ref="change"
            :interval="2000"
          >
            <el-carousel-item v-for="item in swp_ban_list" :key="item">
              <!-- <h3 class="small"> -->
              <img class="small" :src="$baseUrl + item" alt="" />
              <!-- </h3> -->
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="prev" @click="prev">
          <img src="../assets/image/6.png" alt="" />
        </div>
        <div class="next" @click="next">
          <img src="../assets/image/7.png" alt="" />
        </div>
        <div class="top">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ data.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Store image</div>
          <div class="bao">
            <div
              class="bs"
              @click="men(v, i)"
              v-for="(v, i) in swp_upp_list"
              :key="i"
            >
              <p>{{ v.title }}</p>
              <div :class="att == i ? 'lines' : 'lines_un'"></div>
            </div>
          </div>
        </div>
        <img
          src="../assets/image/10.png"
          class="bot_img"
          alt=""
          @click="noneall"
          v-if="flag"
        />
        <img
          src="../assets/image/11.png"
          class="bot_img"
          alt=""
          v-else
          @click="nonealls"
        />
        <!-- 底部 -->
        <div class="shopd" ref="showPanel">
          <div
            :class="i == art ? 'shop_lt' : 'shop_tt'"
            @click="mendian(v, i)"
            v-for="(v, i) in swp_list"
            :key="i"
          >
            {{ v.name }}
          </div>
        </div>
      </div>
      <!-- 轮播图 -->
      <div class="big_swiper">
        <div class="zu">
          <div class="line"></div>
          <div class="tit">{{ qiye_list.name }}</div>
          <div class="line"></div>
        </div>
        <div class="chi">corporate culture</div>
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(it, i) in qiye_list.content"
              :key="i"
            >
              <div class="ona">
                <div class="onb">
                  <div class="onc">{{ it.title.substr(Math.floor(Math.random()*it.title.length), 1) }}</div>
                </div>
              </div>
              <div class="tif">
                <div class="tif_one">{{ it.title }}</div>
                <div class="tif_two" v-html="it.content"></div>
              </div>
            </div>
            <!-- <div class="swiper-slide">Slide 2</div>
            <div class="swiper-slide">Slide 3</div> -->
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-next">
            <img src="../assets/image/7.png" alt="" class="nor" />
          </div>
          <div class="swiper-button-prev">
            <img src="../assets/image/6.png" alt="" class="nor" />
          </div>
        </div>
      </div>
      <!-- 场景设计 -->
      <div class="dsign">
        <div class="wu">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ chang_list.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Video explanation</div>
          <div class="yin">
            <div v-for="(it, i) in chang_list.content" :key="i" class="z_yin">
              <div class="yin_tit">
                <div class="yin_a">{{ it.title }}</div>
                <div class="yin_f">No.{{ i + 1 }}</div>
              </div>
              <div class="toimg">
                <img class="toimg" :src="$baseUrl + it.images[0]" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 消费项目 -->
      <div class="store">
        <div class="wu">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ xiaofei_list.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Consumer items</div>
          <div class="card">
            <zizhi :list="xiaofei_list.content"></zizhi>
          </div>
        </div>
        <img src="../assets/image/5.png" class="shan" alt="" />
      </div>
      <!-- 品牌 -->
      <div class="pin">
        <div class="wu">
          <div class="zu">
            <div class="line"></div>
            <div class="tit">{{ brand_list.name }}</div>
            <div class="line"></div>
          </div>
          <div class="chi">Brand recommendation</div>
          <div class="recommend">
            <div
              v-for="(it, i) in brand_list.content"
              :key="i"
              class="recommend_list"
            >
              <div class="imgt">
                <img
                  class="imgs_s"
                 
                  :src="$baseUrl + it.images[0]"
                  alt=""
                />
              </div>
              <div class="logo">{{ it.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import request from "../utils/request.js";
import headers from "../assembly/head.vue"; //头部
import zizhi from "../assembly/zizhi";
// import Swiper from "swiper";
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import footers from "@/assembly/footer"; //页脚
// import sides from "@/assembly/side"; //侧边
export default {
  name: "",
  data() {
    return {
      one: {},
      two: {},
      customers: {},
      brand_list: {},
      chang_list: {},
      xiaofei_list: {},
      qiye_list: {},
      zudao_data: {},
      zudao_content: "",
      zudao_img: "",
      flag: false,
      swp_list: {},
      swp_upp_list: {},
      swp_ban_list: {},
      art: "", //门店
      att: "", //门店内景
      data: {},
      banner_img: "",
    };
  },

  props: [],
  components: {
    headers,
    zizhi,
    footers,
    // sides,
  },
  computed: {},
  filters: {},
  created() {
    this.indexs();
    this.swper_list();
    this.banner();
    // this.abc();
  },
  mounted() {
    new Swiper(".swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      slidesPerView: 4,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    let _this = this;
    // 页面渲染结束再执行动画
    this.$nextTick(function () {
      new _this.WOW({ live: false }).init();
    });
  },

  methods: {
    async banner() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 16,
        },
      };
      try {
        let res = await request(options);

        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.banner_img = res.data.data.content[0].images[0];
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    //门店消失
    noneall() {
      this.flag = false;
      let isSelf = this.$refs.showPanel;

      isSelf.setAttribute("class", "shopd animated fadeOutDown");
    },
    nonealls() {
      this.flag = true;
      let isSelf = this.$refs.showPanel;

      isSelf.setAttribute("class", "shopd animated fadeInUp");
    },
    prev() {
      this.$refs.change.prev();
    },
    next() {
      this.$refs.change.next();
    },
    men(e, i) {
      this.att = i;
      this.swp_ban_list = e.images;
    },
    mendian(e, i) {
      this.art = i;
      this.swp_upp_list = e.content;
      if (e.content.length != 0) {
        this.swp_ban_list = e[0].images;
      } else {
        this.swp_ban_list = {};
      }
    },
    async swper_list() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 7,
        },
      };
      try {
        let res = await request(options);
        console.log(res, "mendian");
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.data = res.data.data;
        let datas = res.data.data.child.reverse();
        console.log(datas, "----------------");
        this.swp_upp_list = datas[0].content;
        this.swp_ban_list = datas[0].content[0].images;
        // let data = res.data.data.child;
        this.swp_list = datas;
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    async indexs() {
      let options = {
        url: "/index/getContentByChannel",
        type: "post",
        data: {
          channel_id: 1,
        },
      };
      try {
        let res = await request(options);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.qiye_list = res.data.data.child[3];
        this.zudao_data = res.data.data.child[2];
        this.zudao_content = this.zudao_data.content[0].content;
        this.zudao_img = this.zudao_data.content[0].image;
        this.brand_list = res.data.data.child[4];
        this.chang_list = res.data.data.child[6];
        this.xiaofei_list = res.data.data.child[5];
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
/deep/.el-carousel__item.el-carousel__item--card.is-in-stage {
  border-radius: 20px !important  ;
}
.contants {
  position: relative;
  // padding-top: 40px;
  .top {
    width: 100%;
    // position: fixed;
    top: 0;
    position: sticky;
    background: #fff;
    z-index: 10000;
  }
  .banab {
    width: 100%;
  }
  .china {
    width: 100%;
    position: relative;
    background-color: #fff;
    padding-top: 60px;
    .zu {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        width: 120px;
        height: 2px;
        background: #776360;
        opacity: 1;
      }
      .tit {
        font-size: 40px;
        font-weight: bold;
        line-height: 68px;
        color: #776360;
        letter-spacing: 50px;
        opacity: 1;
        margin: 0 30px;
        padding-left: 40px;
      }
    }
    .chi {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #776360;
    }
    .guo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      .guo_l {
        width: 35%;
        .tits {
          font-size: 28px;
          font-weight: bold;
          line-height: 57px;
          color: #776360;
          opacity: 1;
        }
        .ctit {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #252121;
        }
      }
      .guo_r {
        background-image: url("../assets/image/4.png");
        width: 660px;
        height: 355px;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 30px;
        .aimg {
          background-color: #fff;
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
      }
    }
    .mountanin {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      position: relative;
      height: 200px;
      overflow: hidden;
      .s_imgl {
        width: 639px;
        // margin-left: -135px;
        position: absolute;
        top: 0;
        left: -135px;
      }
      .s_imgr {
        width: 639px;
        // margin-right: -380px;
        position: absolute;
        top: 0;
        right: -380px;
      }
    }
  }
  .photo {
	  cursor: pointer;
    /deep/.el-carousel__arrow {
      display: none;
    }
    position: relative;
    .prev {
      position: absolute;
      top: 50%;
      left: 0%;
      z-index: 10;
      img {
        width: 80px;
      }
    }
    .next {
      position: absolute;
      top: 50%;
      right: 0%;
      z-index: 10;
      img {
        width: 80px;
      }
    }
    .top {
      // padding-top: 60px;
      position: absolute;
      top: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.39);
      // opacity: 0.6;
      padding-bottom: 30px;
      .zu {
        display: flex;
        align-items: center;
        justify-content: center;
        .line {
          width: 120px;
          height: 2px;
          background: #fff;
          opacity: 1;
        }
        .tit {
          font-size: 40px;
          font-weight: bold;
          line-height: 68px;
          color: #ffffff;
          letter-spacing: 50px;
          opacity: 1;
          margin: 0 30px;
          padding-left: 40px;
        }
      }
      .chi {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        color: #fff;
      }
      .bao {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        .bs {
          padding: 0 70px;
          border-left: 2px solid #fff;
          position: relative;
          &:last-child {
            border-right: 2px solid #fff;
          }
          p {
            font-size: 28px;
            font-weight: 400;
            line-height: 48px;
            color: #ffffff;
            opacity: 1;
          }
          .lines {
            width: 50px !important;
            height: 5px;
            background-color: #fff;
            opacity: 1;
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
          }
        }
      }
    }
    .bot_img {
      position: absolute;
      z-index: 10;
      left: 50%;
      bottom: 0;
      margin-bottom: 140px;
      width: 37px;
      height: 20px;
    }
    .shopd {
      position: absolute;
      width: 100%;
      padding: 40px 0px 25px;
      bottom: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.39);
      display: flex;
      align-items: center;
      justify-content: center;
      .shop_lt {
        color: #f00;
        margin: 0 47px;
        font-size: 28px;
        font-weight: 400;
        // line-height: 48px;
        color: #ffffff;
        opacity: 1;
        border-bottom: 5px solid #fff;
        padding-bottom: 20px;
      }
      .shop_tt {
        color: #f00;
        margin: 0 47px;
        font-size: 28px;
        font-weight: 400;
        // line-height: 48px;
        color: #ffffff;
        opacity: 1;
        // border-bottom: 5px solid #fff;
        padding-bottom: 20px;
      }
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
  .big_swiper {
    background-image: url("../assets/image/8.png");
    width: 100%;
    height: 626px;
    padding-top: 60px;
    overflow: hidden;
    .zu {
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        width: 120px;
        height: 2px;
        background: #776360;
        opacity: 1;
      }
      .tit {
        font-size: 40px;
        font-weight: bold;
        line-height: 68px;
        color: #776360;
        letter-spacing: 50px;
        opacity: 1;
        margin: 0 30px;
        padding-left: 40px;
      }
    }
    .chi {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #776360;
    }
    .swiper {
      width: 1200px;
      // height: 500px;
      margin: 81px auto 0;
      .swiper-button-next:after,
      .swiper-rtl .swiper-button-prev:after {
        content: "";
      }
      .swiper-button-prev:after,
      .swiper-rtl .swiper-button-next:after {
        content: "";
      }
      .nor {
        width: 80px;
      }
      .swiper-slide {
        font-size: 18px;
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        .ona {
          margin: 0 auto;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-color: #ab9f9d;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          .onb {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: #776360;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            .onc {
              font-size: 50px;
              // font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 57px;
              color: #ffffff;
              opacity: 1;
            }
          }
        }
        .tif {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          .tif_one {
            writing-mode: vertical-lr;
            font-size: 28px;
            font-weight: bold;
            line-height: 30px;
            color: #776360;
            opacity: 1;
            letter-spacing: 2px;
          }
          .tif_two {
            margin-left: 5px;
            writing-mode: vertical-lr;
            font-size: 24px;
            font-weight: 400;
            line-height: 25px;
            color: #252121;
            opacity: 1;
            letter-spacing: 6px;
            line-height: 100%;
          }
        }
      }
    }
  }
  .dsign {
    .zu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .line {
        width: 120px;
        height: 2px;
        background: #776360;
        opacity: 1;
      }
      .tit {
        font-size: 40px;
        font-weight: bold;
        line-height: 68px;
        color: #776360;
        letter-spacing: 50px;
        opacity: 1;
        margin: 0 30px;
        padding-left: 40px;
      }
    }
    .chi {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #776360;
    }
    .yin {
      display: flex;
      align-items: center;
      justify-content: center;
      .z_yin {
        // width: 374px;
        // text-align: center;
        .yin_tit {
          margin-left: 36px;
          position: relative;
          margin-bottom: 20px;
          margin-top: 68px;
          .yin_a {
            display: flex;
            align-items: center;
            padding-left: 47px;
            // justify-content: center;
            width: 136px;
            height: 44px;
            background: linear-gradient(
              90deg,
              #776360 0%,
              rgba(119, 99, 96, 0) 70%,
              rgba(255, 255, 255, 0) 100%
            );
            // box-shadow: 0px 3px 6px rgba(103, 86, 84, 0.4);
            opacity: 0.8;
            border-radius: 10px;
            font-size: 24px;

            font-weight: 400;
            line-height: 41px;
            color: #000;
          }
          .yin_f {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 27px;
            color: #df862e;
            opacity: 1;
            margin-top: -13px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          margin: 0 40px;
        }
        .toimg {
          width: 374px;
          height: 455px;
          background: rgba(0, 0, 0, 0);
          box-shadow: 0px 3px 6px rgba(103, 86, 84, 0.4);
          opacity: 1;
          border-radius: 10px;
        }
      }
    }
  }
  .store {
    width: 100%;
    position: relative;

    .zu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .line {
        width: 120px;
        height: 2px;
        background: #776360;
        opacity: 1;
      }
      .tit {
        font-size: 40px;
        font-weight: bold;
        line-height: 68px;
        color: #776360;
        letter-spacing: 50px;
        opacity: 1;
        margin: 0 30px;
        padding-left: 40px;
      }
    }
    .chi {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #776360;
    }
    .card {
      margin-top: 60px;
    }
    .shan {
      width: 639px;
      margin-left: -148px;
    }
  }
  .pin {
    .zu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      .line {
        width: 120px;
        height: 2px;
        background: #776360;
        opacity: 1;
      }
      .tit {
        font-size: 40px;
        font-weight: bold;
        line-height: 68px;
        color: #776360;
        letter-spacing: 50px;
        opacity: 1;
        margin: 0 30px;
        padding-left: 40px;
      }
    }
    .chi {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #776360;
    }
    .recommend {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
      .recommend_list {
        width: 18.4%;
        margin-right: 2%;
        margin-bottom: 40px;
        .imgt {
          width: 100%;
          // height: 126px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          text-align: center;
          background-color: #fafbfc;
          margin-right: 2.5%;
          margin-bottom: 20px;
          border-radius: 5px;
          overflow: hidden;
          // padding: 18px 0;
		  height: 98px;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  .imgs_s{
			
			  max-height: 78px;
		  }
        }
        .logo {
          margin-top: 10px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 34px;
          color: #252121;
          opacity: 1;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
}
.small {
  height: 1080px;
  width: 100%;
}
</style>
<style>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
